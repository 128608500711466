import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
const moment = require(`moment`);

const LatestBlogPostsComponent = ({ data }) => {
    const { allMarkdownRemark } = data;

    return (
        <div className="border-t border-gray-200 dark:border-gray-700">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {allMarkdownRemark.edges.map(({ node }) => {
                  return (
                    <li key={node.fields.slug} className="py-12">
                        <article>
                            <div className="space-y-2 xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline">
                                <dl>
                                    <dt className="sr-only">Published on</dt>
                                    <dd className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                                        <time dateTime={moment(node.frontmatter.date).format("YYYY-MM-DDThh:mm:ssTZD")}>{moment(node.frontmatter.date).format("MMM DD, YYYY")}</time>
                                    </dd>
                                </dl>
                                <div className="space-y-5 xl:col-span-3">
                                    <div className="space-y-6">
                                        <div>
                                        <h2 className="text-3xl font-bold mb-1 tracking-tight">
                                            <Link
                                                to={node.fields.slug}
                                                className="text-gray-900 dark:text-gray-100">
                                                {node.frontmatter.title}
                                            </Link>
                                        </h2>
                                        </div>
                                        <div className="prose text-gray-500 max-w-none dark:text-gray-400">
                                            {node.frontmatter.summary}
                                        </div>
                                    </div>
                                    <div className="text-base font-medium leading-6">
                                        <Link
                                            to={node.fields.slug}
                                            className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
                                            aria-label={`Read "${node.frontmatter.title}"`}
                                        >
                                        Read more &rarr;
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </li>
                  )
                })}
            </ul>
        </div>
    )
}

LatestBlogPostsComponent.propTypes = {
    data: PropTypes.object
}

export default LatestBlogPostsComponent